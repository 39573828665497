<template>
    <div class="app_container">
        <div class="week_bg">
            <div class="top_bg" style="background-image: url(img/top_bg.png);">
            </div>
            <!-- 上半部分 -->

            <!-- 标题tab -->

            <!-- 抽奖转盘 -->
            <div class="week_bg" style="overflow:scroll;">
                <div class="active_title_2" style="background-image: url(img/active_title_4.png);"></div>
                <!-- <div class="go_back_button" style="background-image: url(img/go_back.png);" @click="goBack"></div> -->
                <div class="lottery_box" style="background-image: url(img/lottery_bg.png);">
                    <div class="lottery_item_box">
                        <div class="lottery_circle_2" style="background-image:url(img/lottery_circle_neww.png)">
                        </div>
                        <div class="lottery_click" @click="start()" style="background-image: url(img/lottery_click.png);">
                        </div>
                    </div>

                </div>
                <div class="lottery_btn_box">
                    <div class="once_btn" style="background-image: url(img/once.png);" @click="start()"></div>
                    <div class="tenth_btn" style="background-image: url(img/tenth.png);" @click="start()"></div>
                </div>


            </div>
            <div class="slide_list" style="background-image: url(img/rank_bg_2.png);margin-top: 20px;">
                <vue-seamless-scroll v-if="listData.length > 0" :data="listData" class="warp">
                    <div class="gift_item"
                        :style="item.rank == '3' ? 'background-image: url(img/level_three.png);' : (item.rank == '2' ? 'background-image: url(img/level_two.png);' : 'background-image: url(img/level_one.png);')"
                        v-for="(item, index) in listData" :key="index">
                        <div class="gift_item_top">
                            <span class="nick_name">{{ item.nickname }}</span>
                            <span class="gift_name">{{ item.name }}</span>
                        </div>
                        <div class="gift_item_mid"></div>
                        <div class="gift_item_bottom">
                            <span class="user_id">ID:{{ item.u_id }}</span>
                            <span class="gift_date">{{ item.create_time }}</span>
                        </div>
                    </div>
                </vue-seamless-scroll>
            </div>

        </div>

        <div class="bottom_bg" style="background-image: url(img/bottom_tip.png);"></div>
        <!-- 弹框 -->
        <div class="mask" v-if="showTipMask">
            <div class="tip_bg" style="background-image: url(img/lucky_good.png);overflow: hidden;">
                <div class="carrot_tip" style="padding: 0 20px 0 20px">
                    超新星权益已生效，恭喜您获得荣耀精品阁抽奖机会~请前往App新人专享福利中领取，更有<span style="color:red">最高88的现金红包</span>待领取~
                </div>
                <div class="i_know" style="background-image: url(img/receive_btn.png);margin: 80px auto 0;"
                    @click="closeTipMask"></div>
            </div>
        </div>
        <div class="mask" v-if="showGiftMask">
            <div class="gift_box" style="background-image: url(img/gift_dialog_bg.png);">
                <div style="width:100%;height:100%;position: relative;overflow: hidden;">
                    <div class="gift_item_box" style="background-image: url(img/gift_item_bg.png);">
                        <img class="gift_pic" src="img/crown_big.png" alt="">
                    </div>
                    <div class="diamond_gift_name">
                        <div class="gift_item_name">精品夺宝</div>
                        <div class="price" style="text-align:center">1次</div>
                        <!-- <div class="diamond_and_price" v-if="gift.price>0">
                            <img class="diamond" src="img/diamond.png" alt="">
                            <span class="price">{{gift.price}}</span>
                        </div> -->
                    </div>
                    <div class="ok_btn" style="background-image: url(img/draw.png);" @click="closeGiftMask"></div>
                </div>

            </div>

        </div>
        <div class="mask_" v-if="showMask">
            <div class="mask_tip">请点击右上角，选择浏览器打开</div>
        </div>
    </div>
</template>
   
<script>
import "@/assets/css/base.css"
import "@/assets/css/seventh.css"
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
    name: "shareIndex",
    data() {
        return {
            showTipMask: false,
            showGiftMask: true,
            showMask: false,
            introduce_code: '',
            listData: [
                {
                    nickname: 'My.青青',
                    name: '王者168皮肤任选一个',
                    u_id: '6057921',
                    create_time: '2023-6-12 21:30:21',
                    rank: '3'
                },
                {
                    nickname: '月圆人更圆',
                    name: '金币66',
                    u_id: '6059833',
                    create_time: '2023-6-12 20:47:39',
                    rank: '1'
                },
                {
                    nickname: '给我一支yan',
                    name: '金币10',
                    u_id: '6067285',
                    create_time: '2023-6-12 20:21:19',
                    rank: '1'
                },
                {
                    nickname: '阿坤',
                    name: '非卖座驾7天',
                    u_id: '6049629',
                    create_time: '2023-6-12 19:53:08',
                    rank: '2'
                },
                {
                    nickname: '晴天胖胖',
                    name: '王者168皮肤任选1个',
                    u_id: '6098752',
                    create_time: '2023-6-12  19:31:15',
                    rank: '3'
                },
                {
                    nickname: 'SJ.杰杰',
                    name: '随机座驾7天',
                    u_id: '6082926',
                    create_time: '2023-6-12  19:30:21',
                    rank: '1'
                },
                {
                    nickname: '王九蛋',
                    name: '金币10',
                    u_id: '6099112',
                    create_time: '2023-6-12  19:27:39',
                    rank: '1'
                },
                {
                    nickname: 'Diji',
                    name: '王者88皮肤任选1个',
                    u_id: '6086124',
                    create_time: '2023-6-12  19:16:19',
                    rank: '2'
                },
                {
                    nickname: 'Ale',
                    name: '靓号AAABBB',
                    u_id: '7779911',
                    create_time: '2023-6-12  18:57:08',
                    rank: '2'
                },
                {
                    nickname: '灵蛇',
                    name: '金币13140',
                    u_id: '6122241',
                    create_time: '2023-6-12  18:38:15',
                    rank: '3'
                },
            ],
        };
    },

    components: {
        vueSeamlessScroll
    },
    created() {
        this.introduce_code = this.$route.query.introduce_code
    },

    watch: {},

    mounted() { },

    methods: {
        start() {
            this.showTipMask = true
        },
        toDownload() {
            window.location.href = 'https://wanyou.chongqinghaoduo.com/h5/index.html#/download?' + this.introduce_code;
        },
        closeTipMask() {
            this.showTipMask = false
            let ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                this.showMask = true
            } else {
                this.showMask = false
            }
            this.submitFn()
        },
        closeGiftMask() {
            this.showGiftMask = false
        },
        submitFn() {
            var t = 1000;
            var hasApp = true
            const u = navigator.userAgent
            const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端或者uc浏览器
            var that = this
            setTimeout(function () {
                if (hasApp) {
                    //    alert('安装了app');
                    //    return false;
                } else {
                    //    alert('未安装app');
                    if (isAndroid) {
                        var link = document.createElement('a');
                        link.setAttribute("download", "app");
                        link.setAttribute("href", "https://fwapk.oss-cn-hangzhou.aliyuncs.com/dp_apk/gf.apk");
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        window.location.href = 'https://wanyou.chongqinghaoduo.com/h5/index.html#/download?' + that.introduce_code;// 下载网址
                    }
                }
                document.body.removeChild(ifr)
            }, 2000)

            var t1 = Date.now()
            var ifr = document.createElement('iframe')
            if (isAndroid) {
                // ifr.setAttribute('src', 'gossip://yuliaokaihei.com') // 安卓链接 不是https:
                // ifr.setAttribute('style', 'display:none')
                // document.body.appendChild(ifr)
                window.location.href = 'player://yuliaokaihei.com' // 安卓链接 不是https:
            } else {
                window.location.href = 'com.dbxq.xmt://' // ios链接 不是https:
            }

            setTimeout(function () {
                var t2 = Date.now()
                if (!t1 || t2 - t1 < t + 100) {
                    hasApp = false
                }
            }, t)
        }

    },

    computed: {}
};
</script>
<style scoped>
.lottery_circle_2 {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    animation: circleRoate 10s infinite linear
}

@keyframes circleRoate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
   
   